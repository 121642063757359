import axios from "axios";
import userService from "@/services/user.service";

export default {

  login(data, headers) {
    const ruta = data.isAdmin ? 'admin' : '';
    return axios.post(`/login/${ruta}`, data, { headers });
  },

  resendCode(headers) {
    return axios.post(`/resend-code`, {}, { headers });
  },

  resendPass(data, headers) {
    return axios.post(`/resend-pass/create`, data, { headers });
  },

  getInfoUser(data, headers) {
    return axios.post(`/data`, data, { headers });
  },

  async getAllInfo(option, headers) {
    await userService.verify()
    return axios.get(`/${option}`, { headers });
  },

  getStatusImage(payload, headers) {
    return axios.get(`/${payload.option}-img/${payload.image}`, { headers });
  },

  async getInfoById(payload, headers) {
    await userService.verify()
    return axios.get(`/${payload.option}/${payload.id}`, { headers });
  },

  getByDate(payload, headers) {
    return axios.get(`/${payload.option}/${payload.start}/${payload.end}`, { headers });
  },

  async deleteItem(payload, headers) {
    await userService.verify()
    let str = `/${payload.option}/${payload.id}`
    if(payload?.subvalue !== undefined) str =  str + `/${payload.subvalue}`
    return axios.delete(str, {
      data: payload,
      headers: headers
    });
  },

  async deleteSubscriptions(payload, headers) {
    await userService.verify()
    let str = `/${payload.option}`
    if(payload?.subvalue !== undefined) str =  str + `/${payload.subvalue}`
    return axios.delete(str, {
      data: payload,
      headers: headers
    });
  },

  async editItem(payload, headers) {
    await userService.verify()
    const url = payload.paramId ? `/${payload.option}/${payload.paramId }` : `/${payload.option}`
    return axios.put(url, payload.item, { headers });
  },

  async addItem(payload, headers) {
    await userService.verify()
    const url = payload.paramId ? `/${payload.option}/${payload.paramId }` : `/${payload.option}`
    return axios.post(url, payload.item, { headers });
  },



}