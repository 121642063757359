import Vue from "vue";
import VueRouter from "vue-router";
import userService from "../services/user.service";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "inicio",
    component: () => import("../views/Home"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/inicio",
    name: "inicio",
    component: () => import("../views/Home"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/elige-tu-membresia",
    name: "planSelection",
    component: () => import("../views/planSelection"),
    meta: {
      log: false,
      admin: false,
      auth: false,
    },
  },
  {
    path: "/elige-tu-plan-free-trial",
    name: "planSelectionFreeTrial",
    component: () => import("../views/planSelectionFreeTrial"),
    meta: {
      log: false,
      admin: false,
      auth: false,
    },
  },
  {
    path: "/cambiar-metodo-de-pago",
    name: "changePaymentMethod",
    component: () => import("../views/changePaymentMethod"),
    meta: {
      log: false,
      admin: false,
      auth: false,
    },
  },
  {
    path: "/bienvenido",
    name: "bienvenido",
    component: () => import("../views/bienvenido"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/web-app",
    name: "inicio",
    component: () => import("../views/webappWrapper.vue"),
    meta: {
      log: true,
      admin: false,

      auth: false,
    },
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },

  {
    path: "/auth",
    name: "autentificacion",
    component: () => import("../views/Autentificacion"),
    meta: {
      log: true,
      admin: true,

      auth: false,
    },
  },

  {
    path: "/administrador",
    name: "Dashboard",
    component: () => import("../views/adminWrapper"),
    meta: {
      log: true,
      admin: true,

      auth: true,
    },
  },

  /**
  {
    path: '/aviso-privacidad',
    name: 'Aviso de Privacidad',
    component: () => import('../views/AvisoPrivacidad'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
   */
  {
    path: "/iniciar-sesion",
    name: "iniciar sesion",
    component: () => import("../views/userLogin"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/recuperar-cuenta",
    name: "recuperar cuenta",
    component: () => import("../views/userRecoverAccount"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/registro",
    name: "registro",
    component: () => import("../views/userRegister"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },

  {
    path: "*",
    name: "404",
    component: () => import("../views/Error404"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/terminos-y-condiciones",
    name: "terms",
    component: () => import("../views/terms"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
  {
    path: "/aviso-de-privacidad",
    name: "privacy",
    component: () => import("../views/privacy"),
    meta: {
      log: false,
      admin: false,

      auth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const meta_admin = to.matched.some((record) => record.meta.admin);
  const meta_log = to.matched.some((record) => record.meta.log);
  const meta_auth = to.matched.some((record) => record.meta.auth);
  const tokenexp = await userService.tokenexp();
  let check = false;
  let admin = false;
  let type = "";
  if (tokenexp) {
    localStorage.removeItem("tkn");
    next();
  }

  const response = await userService.getData();

  if (response.success) {
    check = true;
    let isAdmin = userService.isAdmin(response.data);
    admin = isAdmin.admin;
    type = isAdmin.type;
  }

  const ath = await userService.checkAuth();

  if ((to.path === "/login" || to.path === "/iniciar-sesion") && check) {
    localStorage.removeItem("tkn");
    next();
  }

  if (!meta_log) next();
  if (meta_log && !check) next("/iniciar-sesion");
  if (meta_admin) {
    if (admin) {
      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem("tkn");
          next("/login");
        }
      } else {
        next();
      }
    } else {
      localStorage.removeItem("tkn");
      next("/");
    }
  } else {
    next();
  }
});

export default router;
