"use strict";

const local = 'http://localhost:3000'
const cpanel = 'https://umanamethod.xyz'
const cpanel2 = 'https://umana.com.mx'

exports.url = cpanel2 + '/api';

const CONSTANTS = {
  GETTING_INFO: "GET_INFO",
  GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
  GETTING_IMG: "GET_IMG",
  GET_IMG_SUCCESS: "GET_IMG_SUCCESS",
  SET_HISTORY_OPTION: "SET_HISTORY_OPTION",
  SET_ADDED: "SET_ADDED",
  SET_STATUS: "SET_STATUS",
  GETTING_MSG: "GETTING_MSG",
  GET_MSG: "GET_MSG",
  ERROR_MSG: "Ha ocurrido un error al intentar realizar la solicitud",
  SUCCESS_MSG: "Mensaje enviado con éxito",
  ERROR_STATUS: "error",
  SUCCESS_STATUS: "success",
  ERROR_INPUTS: "Campos no válidos",
  SUCCES_SUSCRIPTION: "Suscripción exitosa",
  SET_TOKEN: "SET_TOKEN",
  SET_IDENTITY: "SET_IDENTITY",
  GET_INFO_IDENTITY: "GET_INFO_IDENTITY",
  CLEAN_VALUE: "CLEAN_VALUE",
}
exports.CONSTANTS = CONSTANTS;

function getErrorMessage(message) {
  if (!message) return createError(CONSTANTS.ERROR_MSG);

  if (Array.isArray(message)) {
    const stringErrors = message.map(err => err.msg).join(', ')
    return createError(stringErrors, message)
  } else if (typeof message === 'string') {
    return createError(message);
  } else {
    return createError(CONSTANTS.ERROR_MSG);
  }
}

function createError(message_error, detail = []) {

  return {
    success: false,
    status: CONSTANTS.ERROR_STATUS,
    code: 500,
    message: message_error,
    data: null,
    detail
  }
}

exports.getErrorMessage = getErrorMessage;