import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import usuarios from './modules/usuarios'
import info from './modules/info'
import suscripciones from './modules/suscripciones'
import productos from './modules/productos'
import personalizacion from './modules/personalizacion'
import storeOnline from './modules/storeOnline'
import factura from './modules/factura'
import categories from './modules/categories'
import marcas from './modules/marcas'
import atributos from './modules/atributos'
import gallery from './modules/gallery'
import related from './modules/related'
import payment from './modules/payment'
import subcategories from './modules/subcategories'
import proclass from './modules/proclass'
import memberships from './modules/memberships'
import equipment from './modules/equipment'
import recetas from './modules/recetas'
import reccategories from './modules/reccategories'
import recsubcategories from './modules/recsubcategories'
import alumn from './modules/alumn'
import projectInfo from './modules/projectInfo'
import sociales from './modules/sociales'
import comunidad from './modules/comunidad'
import weekPlan from './modules/weekPlan'
import clases from './modules/clases'
import herramientas from './modules/herramientas'
import tendencias from './modules/tendencias'
import membresias from './modules/membresias'
import program from './modules/program'
import coupon from './modules/coupon'
import prices from './modules/prices'
import discounts from './modules/discounts'
import progresclass from './modules/progresclass'
import chatroom from './modules/chatroom'
import chatmessage from './modules/chatmessage'
import adminchatmessage from './modules/adminchatmessage'
import adminchatroom from './modules/adminchatroom'
import chatmessagelist from './modules/chatmessagelist'
import chatroomlist from './modules/chatroomlist'
import programindv from './modules/programindv'
import programcollabs from './modules/programcollabs'
import programrel from './modules/programrel'
import testimonios from './modules/testimonios'
import questions from './modules/questions'
import goals from './modules/goals'
import comments from './modules/comments'
import daily from './modules/daily'
import feature from './modules/feature'
import statistics from './modules/statistics'
import reviews from './modules/reviews'
import live from './modules/live'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    colors,
    copy,
    header,
    logo,
    main,
    mainmtr,
    usuarios,
    info,
    suscripciones,
    productos,
    personalizacion,
    storeOnline,
    factura,
    categories,
    marcas,
    atributos,
    gallery,
    related,
    payment,
    subcategories,
    proclass,
    memberships,
    equipment,
    recetas,
    reccategories,
    recsubcategories,
    alumn,
    projectInfo,
    sociales,
    comunidad,
    weekPlan,
    clases,
    herramientas,
    tendencias,
    membresias,
    program,
    coupon,
    prices,
    discounts,
    progresclass,
    chatroom,
    chatmessage,
    adminchatmessage,
    adminchatroom,
    chatmessagelist,
    chatroomlist,
    programindv,
    programcollabs,
    programrel,
    testimonios,
    questions,
    comments,
    daily,
    goals,
    comments,
    feature,
    statistics,
    reviews,
    live
  }
})
