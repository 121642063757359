import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'vue-dayjs'
import axios from 'axios'
import VueAxios from 'vue-axios'
import AOS from 'aos'
import Vue2TouchEvents from 'vue2-touch-events'
import VueSocketIO from 'vue-socket.io';
import socketio from 'socket.io-client';
import { url } from './global/index'
import { register } from 'swiper/element/bundle';

import 'aos/dist/aos.css'

const SocketInstance = socketio.connect(url.replace("/api",""));
Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketInstance
}));

Vue.use(Vue2TouchEvents)

Vue.use(dayjs)

Vue.use(VueAxios, axios)

axios.defaults.baseURL = url;

Vue.config.productionTip = false
register();

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
